import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../../Components/Widget";
import StoreListItem from "../../Components/StoreListItem";

class StoreListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false
    };
  }

  render() {
    let { store, navActions } = this.props;
    let { showSpinner } = this.state;
    if (showSpinner) {
      return <Widget.Spinner />;
    }

    return (
      <Wrapper>
        <Widget.MaxWidth width={1140} extraCss="padding: 70px 20px;">
          <div className="title">精選品牌店家</div>
          <div className="content">
            {store.map((item, i) => (
              <StoreListItem
                key={"store-list-item" + i}
                item={item}
                css="margin-bottom: 50px;"
                onClick={() => navActions.push(`/store/?id=${item.id}`)}
              />
            ))}
            <StoreListItem
              css={`
                height: 0px;
                & > .left {
                  margin-right: 0px;
                  height: 0px;
                  width: 0px;
                }
              `}
            />
            <StoreListItem
              css={`
                height: 0px;
                & > .left {
                  margin-right: 0px;
                  height: 0px;
                  width: 0px;
                }
              `}
            />
          </div>
        </Widget.MaxWidth>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > div {
    & > .title {
      margin-bottom: 70px;
      letter-spacing: 3.9px;
      font-weight: 500;
      font-size: 26px;
    }

    & > .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 500px) {
    & > div {
      & > .content {
        justify-content: center;
      }
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      store: Selectors.getStoreList(state)
    }),
    ActionCreator
  )(StoreListingPage)
);
