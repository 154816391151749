import React, { Component } from "react";
import styled from "styled-components";
import Image from "../Image";

class StoreListItem extends Component {
  render() {
    let { css, item = "", onClick } = this.props;

    return (
      <Wrapper css={css} onClick={onClick}>
        <Image background className="left" src={item.image || ""} />
        <div className="right">{item.name}</div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 250px;
  width: 100%;
  cursor: pointer;

  & > .left {
    margin-right: 20px;
    border: 1px solid #bd9f84;
    border-radius: 50%;
    width: 126px;
    height: 126px;
  }

  & > .right {
    max-width: 100px;
    overflow: hidden;
    font-size: 14px;
  }

  @media screen and (max-width: 1021px) {
    max-width: 300px;
  }

  @media screen and (max-width: 925px) {
    max-width: 250px;
  }

  @media screen and (max-width: 775px) {
    max-width: 225px;
  }

  @media screen and (max-width: 700px) {
    max-width: 185px;

    & > .left {
      width: 100px;
      height: 100px;
    }

    & > .right {
      max-width: 65px;
    }
  }

  @media screen and (max-width: 580px) {
    max-width: 225px;

    & > .right {
      max-width: 100px;
    }
  }

  @media screen and (max-width: 500px) {
    max-width: 300px;
  }

  @media screen and (max-width: 414px) {
    max-width: 220px;
  }

  ${props => props.css};
`;

export default StoreListItem;
